import { Component, OnInit, ElementRef, ViewChild, inject } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, RouterOutlet } from '@angular/router';
import { SocketService } from '../services/socket/socket.service';
import { GlobalService } from '../services/global/global.service';
import { UserService } from '../services/user/user.service';
import { ConfirmationService, SharedModule } from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import { AllAppsComponent } from '../general/all-apps/all-apps.component';



import {ConfirmPopupModule} from 'primeng/confirmpopup';

import { VariablesService } from '../services/variables/variables.service';
import { ChatComponent } from '../tools/chat/chat.component';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { ProgressBarModule } from 'primeng/progressbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { SidebarModule } from 'primeng/sidebar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { ConfigService } from '../services/app-config/config.service';
import { CommonService } from '../services/common/common.service';
// import {HmacSHA256, enc} from 'crypto-js'; 

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    providers: [ConfirmationService, DialogService],
    standalone: true,
    imports: [NgIf, ProgressSpinnerModule, NgClass, TieredMenuModule, SidebarModule, ChatComponent, TooltipModule, NgFor, BadgeModule, OverlayPanelModule, SharedModule, ProgressBarModule, DividerModule, ConfirmDialogModule, ButtonModule, RouterOutlet, ToastModule, DialogModule]
})
export class MainComponent implements OnInit {
  isOnline: boolean = false;
  user_data: any = {
    userphotofile: ''
  };
  notification_count: number = 0;
  notification_data: any = [];


  returnUrl: any = '';
  show_side_left: any = false;
  show_message: any = false;
  secretKey = "YourSecretKeyForEncryption&Descryption";

  module:string='';
  uploadMessage:string='';
  uploading:boolean = false;
  uploadActive:boolean =false;
  @ViewChild('notifbtn') notifbtn!: ElementRef;

  menus: any = [
    {
      icon: 'ic_dashboard_regular',
      icon_filled: 'ic_dashboard_filled',
      name: 'Dashboard',
      active: true,
      link:''
    },
    {
      icon: 'ic_all_apps_regular',
      icon_filled: 'ic_all_apps_filled',
      name: 'All Apps',
      link:'allapps'
    },

    // {
    //   icon: 'ic_user_management_regular',
    //   icon_filled: 'ic_user_management_filled',
    //   name: 'System Management',
    //   link: 'system',
    //   sub_modules: []
    // },
    {
      icon: 'ic_helpdesk_regular',
      icon_filled: 'ic_helpdesk_filled',
      name: 'Helpdesk',
      link: 'helpdesk'
    }, 

  ];

  right_sidemenu: any = [
    {
        label: 'Messages',
        icon: 'pi pi-fw pi-comments',
        command: (event:any) => {
          // this.show_message=true;
          this.openMessages();
        }
    },
    {
        label: 'Notification',
        icon: 'pi pi-fw pi-bell',
        command: (event:any) => {
          console.log("eee",event);
          this.openNotification(this.notificationelem, event)
        }
    },
    {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        command: (event:any) => {
          // this.openNotification(this.notification, event)
          this.logoutConfirm();
        }
    },
  ];


  @ViewChild('notification', { static: true })
  notificationelem!: ElementRef;
  ref:any = DynamicDialogRef;

   constructor(
    private router: Router,
    public userservice: UserService,
    private confirmationService: ConfirmationService,
    private socketservice: SocketService,
    public globalservice: GlobalService, 
    public configservice: ConfigService, 
    public commonservice: CommonService,
    public dialogService: DialogService
    ) {

      router.events.subscribe(event => {
        if(event instanceof NavigationStart) {
          // this.globalservice.showLoading();
          console.log("event started")
        }else if(event instanceof NavigationEnd) {
          this.globalservice.loader = false;
          console.log("event end")
        }
        // NavigationEnd
        // NavigationCancel
        // NavigationError
        // RoutesRecognized
      });
      


     }

  ngOnInit(): void {

    this.globalservice.setIcon();
    this.uploadActive =false;
    // let current_path:any  = this.router.url;
    // if(current_path=='/messages') {
    //   this.openMessages();
    // }
    if(!this.commonservice.main_page_called){
          this.globalservice.setTitle('i95');
          // initialize connection to socket
          this.socketservice.connectSocket();
          
      
          // get the user profile
          this.userservice.getUserProfile();
      
          // set all the user access
          this.userservice.setUserAccess();
      
          // console.log('all_access',this.userservice.all_access)
          
      
          // get the user data
          this.commonservice.loadCommon('user_data',(err:any,result: any) => {
            // console.log(result);
            let get_select_project:any= this.globalservice.getData(result,'setting_name','select_project');
            if(get_select_project!=undefined){
                this.commonservice.projectId = Number(get_select_project.data);
            }
          });
      

          this.commonservice.getUserDataConfig('homepage',(result: any) => {
              // console.log(result);
          });
      
          // listen to notifications
          // console.log(this.socketservice);
      
          if(!this.configservice.userid){
            this.globalservice.showError('You are not login.');
            this.logout();
          }
      
          this.socketservice.socket.on('notification_'+this.configservice.userid, (return_data: any) => {
      
            if(this.commonservice.data.users==undefined){
              
              this.commonservice.loadCommon('users', (err: any, result: any) => {
                return_data.details = this.processNotifDetails(return_data); 
                this.globalservice.showInfo(return_data.details, 'Notification');
                this.notification_count += 1;
                this.notification_data.unshift(return_data);
              });
      
            }else{
              return_data.details = this.processNotifDetails(return_data); 
              this.globalservice.showInfo(return_data.details, 'Notification');
              this.notification_count += 1;
              this.notification_data.unshift(return_data);
            }
            
          });
    
          // var system_menu: any = this.globalservice.getData(this.menus, 'link', 'system');
          // system_menu.sub_modules = this.globalservice.system_modules;
          this.openMessageSocket();
          this.commonservice.main_page_called = true;
    }



  }

  new_message_data: any = {}
  openMessageSocket(){

    this.socketservice.socket.on('incoming_message_'+this.configservice.userid, (return_data: any) => {
      
      if(this.commonservice.openchat){
        this.commonservice.incomingMessage(return_data); 
      }else{
        this.globalservice.showchatNotif(return_data);
        this.new_message_data = return_data;
        this.commonservice.loadCommon('users');
      }
    });


    this.socketservice.socket.on('CLAIM_MASTER_IMPORTEXCEL_'+this.configservice.userid+'_NOTIF', (return_data: any) => { 
      this.notifbtn.nativeElement.click();
      this.module = return_data.module;
      this.uploadMessage = return_data.message;
      this.uploading =true;
      this.uploadActive =true;
      this.notification_count = 1;
    });

    this.socketservice.socket.on('CLAIM_MASTER_IMPORTEXCEL_'+this.configservice.userid, (return_data: any) => { 
      this.uploading = false;
      this.uploadActive =true;
      this.notification_count = 1;
    });


  }



  isSetHome():boolean{

    return false;
  }
  setAsHome(){
   
    this.commonservice.updateUserDataConfig('homepage', window.location.pathname);
    if(this.isSetHome()){
      this.globalservice.showInfo('Already set as homepage.');
    }

    
    
  }
  
  darkModeStatus(){

    let res:boolean = false;

    if(this.commonservice.data.user_data){
      var dm_data:any = this.globalservice.getData(this.commonservice.data.user_data,'setting_name', 'dark_mode');
      if(dm_data){
        var n:any = Number(dm_data.data);
        res =  (n) ? true : false;
      }
    }


    // console.log(dm_data);

    return res;

  }
  toggleDarkMode(){
    var dm_status = this.darkModeStatus();

    if(!this.commonservice.data.user_data){
      this.commonservice.data.user_data = [];
      this.commonservice.data.user_data.push({setting_name: 'dark_mode', data: 1});
    }else{
      var dm_data:any = this.globalservice.getData(this.commonservice.data.user_data,'setting_name', 'dark_mode');
      if(dm_status){
        dm_data.data = 0;
      }else{
        dm_data.data = 1;
      }
    }
    this.commonservice.updateUserSettings('dark_mode');
    // console.log(this.commonservice.data.user_data);
  }

  logoutConfirm() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to logout?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        key: 'logoutdialog',
        accept: () => {
          this.logout();
        }
    });
}

 logout(){
  // this.userservice.logout();
  // this.socketservice.destroySocket();
  // this.globalservice.logout();
  this.userservice.logout();
  // window.location.reload();
 }

 processNotifDetails(notif: any= {}){
  if(notif.details.includes('(from)')){
    var from_user:any = this.globalservice.getData(this.commonservice.data.users, 'id', notif.from);
    var find = '(from)'; 
    var replace = '<b>'+from_user.first_name+' '+from_user.last_name+'</b>';
    return notif.details.replace(new RegExp(find, 'g'), replace);
  }else{
    return notif.details;
  }

 }
 

 openNotification(notif:any = {}, e:any = {}){
    if(this.notification_data.length<=0){
      this.userservice.getNotification().subscribe(e => {
        var data:any = e;
        this.notification_count = data.data.unread_total;
        this.notification_data = data.data.notifications;
      },(error) => {        
        
      });
    }
    

    notif.toggle(e);
    this.notification_count = 0;

    this.userservice.readAllNotification();

    this.commonservice.loadCommon('users', (err: any, result: any) => {
      for(let notif of this.notification_data){
        notif.details = this.processNotifDetails(notif);
      }
    });
    // if(this.notification_data)

 }


  getUserProfile(){
    this.userservice.loadUserprofile().subscribe(e => {
          
      let data = e;
      if(data.success){
        var enc = this.configservice.encrypt(JSON.stringify(data.data));
        localStorage.setItem('user_profile',enc);  
        this.userservice.getUserProfile();  

        if(!this.userservice.user_profile.active){
          this.globalservice.showError('Your Account has been deactivated. You will be logout.');
  
          setTimeout(() => {
            this.logout();
          }, 3000);
        }

      }
  
    },(error) => {        
      
    });
  }

  goBack(){
    this.router.navigateByUrl(this.returnUrl);
  }

  goRefresh(){
  this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};

  let currentUrl = this.router.url + '?';

  this.router.navigateByUrl(currentUrl)
    .then(() => {
      this.router.navigated = false;
      // alert(this.router.url);
      this.router.navigate([this.router.url]);
    });

  }

  changeRoute(r: any = ''){
    
    console.log(r)
    this.show_side_left = false;
    
    if(r=='allapps'){
      this.showAllApps();
    }else{
      this.globalservice.Route(r);
    }
    

  }

  openItem(i: any={}){

    var selected = i.selected;
    for(let item of this.menus){
      item.selected = false;
    }
    if(selected){
      i.selected = false; 
    }else{
      i.selected = true; 
    }


  }

  openMessages(chat_session_id:any=0){
    if(chat_session_id){
      this.globalservice.messageService.clear();
    }
    
    this.ref = this.dialogService.open(ChatComponent, {
      showHeader: false,
        header: 'iMessage',
      width: 'auto',
      modal: true,
      data: {
        session_id: chat_session_id
      },
      styleClass: 'i-message-container',
      contentStyle: {"max-height": "90vh", "min-height": "90vh", "width": "900px","max-width": "95vw", "overflow": "auto", "padding": "0px"},
      baseZIndex: 10000
    });
  }

  showAllApps() {
    this.ref = this.dialogService.open(AllAppsComponent, {
        header: 'All Applications',
        width: 'auto',
        styleClass: 'all-apps-container',
        contentStyle: {"max-height": "100vh", "min-height": "80vh", "max-width": "95vw", "min-width": "90vw", "overflow": "auto"},
        baseZIndex: 10000
    });

    // this.ref.onClose.subscribe((product: any) =>{

    // });
}

// userNameShow():boolean{
//   let path: any = (window.location.pathname.split('/')[1]) ? window.location.pathname.split('/')[1] : '';
// 	return path == 'timecard' ? true : false;
// }



}
